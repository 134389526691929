/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    h3: "h3",
    ul: "ul",
    li: "li",
    a: "a"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, "스탠다드아웃 81번째 로그에서는 AWS 새소식들, 당근마켓 쇼핑앱 2위, #MayThe4th 해시태그 등에 대해 이야기를 나눴습니다."), "\n", React.createElement(_components.h3, null, "AWS 새 리전 소식"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://aws.amazon.com/ko/about-aws/whats-new/2020/04/announcing-aws-africa-cape-town-region/?nc1=h_ls"
  }, "새 AWS 아프리카(케이프타운) 리전 소개")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://aws.amazon.com/ko/about-aws/whats-new/2020/04/announcing-the-new-aws-europe-milan-region/?nc1=h_ls"
  }, "새로운 AWS EU(밀라노) 리전 발표")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.allthingsdistributed.com/2020/04/aws-launches-europe-milan-region.html"
  }, "Reaffirming our commitment to Italy: Introducing the AWS Europe (Milan) Region - All Things Distributed")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.allthingsdistributed.com/2020/04/aws-launches-europe-milan-region.html"
  }, "Werner Vogels 블로그에 긴 글로 소개")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://github.com/Miserlou/Zappa"
  }, "Miserlou/Zappa: Serverless Python")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://aws.amazon.com/ko/blogs/aws/in-the-works-aws-region-in-indonesia/"
  }, "In the Works – AWS Region in Indonesia | AWS News Blog")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.yna.co.kr/view/AKR20191226070100063"
  }, "세종에 세계 최대 규모 네이버 데이터센터…2022년 준공 | 연합뉴스")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://ebadak.news/2020/04/18/jeff-bezos-letter-2019/"
  }, "아마존 제프 베조스의 2019년 연례 주주 서한 (번역) – 이바닥늬우스")), "\n"), "\n", React.createElement(_components.h3, null, "AWS 이미지 빌더, 우분트 등 주요 리눅스 지원"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://aws.amazon.com/ko/about-aws/whats-new/2020/04/ec2-image-builder-adds-support-ubuntu-rhel-centos-sles/"
  }, "EC2 이미지 빌더, Ubuntu, RHEL, CentOS 및 SLES에 대한 지원 추가")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.packer.io/"
  }, "Packer by HashiCorp")), "\n"), "\n", React.createElement(_components.h3, null, "AWS의 신 서비스들"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://aws.amazon.com/ko/about-aws/whats-new/2020/04/aws-chatbot-now-generally-available/?nc1=h_ls"
  }, "AWS Chatbot 정식 출시")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://blog.naver.com/sehyunfa/221924899955"
  }, "AWS Chatbot과 슬랙(Slack)을 연.. : 네이버블로그")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://aws.amazon.com/ko/about-aws/whats-new/2020/04/introducing-amazon-appflow/"
  }, "Amazon AppFlow 소개")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://aws.amazon.com/ko/eventbridge/integrations/"
  }, "Amazon EventBridge 통합")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://aws.amazon.com/ko/about-aws/whats-new/2020/04/amazon-cloudwatch-synthetics-generally-available/?nc1=h_ls"
  }, "Amazon CloudWatch Synthetics 정식 출시")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.statuspage.io/powered-by?utm_campaign=status.robinhood.com&utm_content=status-pages&utm_medium=powered-by&utm_source=inapp"
  }, "Companies everywhere are powered by Statuspage | Statuspage")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.githubstatus.com/"
  }, "GitHub Status")), "\n"), "\n", React.createElement(_components.h3, null, "GitHub과 GitLab 이야기"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://gitlab.com/gitlab-com/gl-infra"
  }, "GitLab Infrastructure Team · GitLab")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://github.com/features/actions"
  }, "Features • GitHub Actions")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://bitbucket.org/product/"
  }, "Bitbucket | The Git solution for professional teams")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.mercurial-scm.org/"
  }, "Mercurial SCM")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://bitbucket.org/eunjeon/seunjeon/src/master/"
  }, "eunjeon / seunjeon — Bitbucket")), "\n"), "\n", React.createElement(_components.h3, null, "당근마켓, 안드로이드 쇼핑앱 MAU 2위"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.bloter.net/archives/379366"
  }, "'당근마켓' 일 사용자, 11번가·위메프 넘어섰다 | Bloter.net")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "http://tv.jtbc.joins.com/yurangmarket"
  }, "유랑마켓 | 프로그램 | JTBC")), "\n"), "\n", React.createElement(_components.h3, null, "#MayThe4th 해시태그"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://twitter.com/disneyplus/status/1254772307941191686"
  }, "Disney+ on Twitter: \"By sharing your message with us using #MayThe4th... https://t.co/G0AyToufQ5\" / Twitter")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://twitter.com/lunamoth/status/1255097180961927168"
  }, "lunamoth on Twitter: \"https://t.co/CywDAouwGH")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://twitter.com/chrismessina/status/1254853473138401281?s=20"
  }, "Chris Messina on Twitter: \"By using any hashtag, in digital or physical media,...")), "\n"), "\n", React.createElement(_components.h3, null, "시피의 부동산 이야기"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "http://realty.chosun.com/site/data/html_dir/2020/04/17/2020041702885.html"
  }, "'진짜 부자는 강남아파트 안 살아'…회장님 줄줄이 모여사는 이곳")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "http://www.bizhankook.com/bk/article/19706"
  }, "단독 김범수 카카오 의장, 147억 분당 초호화 주택 최초 공개 - 한국비즈")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://movie.daum.net/moviedb/main?movieId=130350"
  }, "다운튼 애비 | 다음영화")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.44bits.io/ko/post/home-network-with-ubiquiti-unifi-products-0"
  }, "유비쿼티 유니파이(Ubiquiti Unifi) 제품 기반 홈 네트워크 구축기 | 44BITS")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://news.joins.com/article/23573158"
  }, "담장 없애랬더니 집 요새화…판교의 ‘중정형’ 단독주택 - 중앙일보")), "\n"));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
